import React from 'react'

import Link from './link'
import Image from './hover-image'
import { cleanUrl } from '@cms/utils'

const List = ({ data }) => {
  const list = data?.sort(({ year: y1 }, { year: y2 }) => {
    return parseInt(y2) - parseInt(y1)
  })

  return (
    <div className="years-container">
      {list.map(({ year, thumbnail, id, link }, i) => (
        <div className="years-items" key={id}>
          <Link href={link}>
            <Image
              // key={year}
              title={year}
              desc={i === list.length - 1 ? 'or before' : undefined}
              src={cleanUrl(
                `${process.env.NEXT_STRAPI_PUBLIC_ENDPOINT}/${thumbnail.responsiveImages.urlPrefix}`
              )}
              placeholder={thumbnail.responsiveImages.placeholder}
              ratio={1 / 3}
              bottomText={year}
              alt={`${year}'s works`}
              // srcsetSize={[300, 400, 500, 600, 720, 1080, 1440, 1920]}
              sizes="(min-width: 94rem) 900px, calc(100vw - 40px)"
            />
          </Link>
        </div>
      ))}
    </div>
  )
}

export default List
