import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import colors from '../../colors'
import { CloseIcon, HamburgerIcon } from './icons'
import MenuModal from './modal'

const MobileMenu = ({ items }) => {
  const { pathname } = useRouter()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [open])

  return (
    <>
      <div className="menu-toggle" onClick={() => setOpen((o) => !o)}>
        {open ? (
          <>
            <CloseIcon color={colors.background} size="20px" />
          </>
        ) : (
          <HamburgerIcon color={colors.secondary} size="24px" />
        )}
      </div>
      {/* If the menu is open, render the menu modal */}
      {open && <MenuModal items={items} />}
    </>
  )
}

export default MobileMenu
