import { useRouter } from 'next/router'
import React from 'react'

import Link from '../../link'

const MenuModal = ({ items }) => {
  const { pathname } = useRouter()

  return (
    <>
      <div className="menu-overlay" />
      <nav className="menu-content">
        {items.map(([name, link]) => (
          <Link
            className="menu-link"
            key={name}
            href={link}
            aria-current={pathname === link ? 'page' : undefined}
          >
            {name}
          </Link>
        ))}
      </nav>
    </>
  )
}

export default MenuModal
