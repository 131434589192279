import { useRouter } from 'next/router'
import React from 'react'

import Link from '../link'
import MobileMenu from './mobile-menu'

const Header = ({ items, logo }) => {
  const { pathname } = useRouter()
  return (
    <div className="header">
      <div className="container">
        <div className="row align-center">
          <div className="column">
            {/* @ts-ignore */}
            <LogoSection src={logo} />
          </div>
          <div className="column hide-on-mobile">
            <div className="row justify-end">
              {items.map(([name, link]) => {
                // Check if the link matched the current page url
                const isCurrentPage = pathname === link

                return (
                  <Link
                    className={
                      'header-items' + (isCurrentPage ? ' active' : '')
                    }
                    key={name}
                    href={link}
                    aria-current={isCurrentPage ? 'page' : undefined}
                  >
                    {name}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <MobileMenu items={items} />
    </div>
  )
}

const LogoSection = ({ className, src }) => (
  <Link href="/">
    <div className={className + ' logo-section'}>
      <div className="logo-container">
        <img src={src} width="40px" height="40px" alt="logo" />
      </div>
      <div className="text-area">
        <b>{'CHAN KWAN LOK'}</b>
        <div className="spread-evenly">
          <b>{'陳'}</b>
          <b>{'鈞'}</b>
          <b>{'樂'}</b>
        </div>
      </div>
    </div>
  </Link>
)

export default Header
