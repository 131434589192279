import React from 'react'

import Image from '@cms/image'

const Hero = ({
  src,
  placeholder,
  deviceSizes,
}: {
  src: string
  placeholder: string
  deviceSizes: Array<number>
}) => {
  return (
    <Image
      className="hero"
      src={src}
      placeholder={placeholder}
      alt="hero image"
      deviceSizes={deviceSizes}
      eager
    />
  )
}

export default Hero
