import React, { useState, useRef, ReactNode, useEffect } from 'react'

import setting from '@cms/config/settings.json'
import { useInView } from 'react-intersection-observer'

import './index.scss'

const _deviceSizes = setting.deviceSizes
const _extensions = setting.extensions

export const loader = ({
  w,
  src,
  ext = 'jpg',
}: {
  w: number
  src: string
  ext?: 'jpg' | 'avif' | 'webp'
}) => {
  const [url] = src.split(/\.(gif|jpe?g|bmp|png)$/gim)
  if (url.endsWith('/')) return `${url}${w}w.${ext}`
  return `${url}/${w}w.${ext}`
}

const getSrcSet = ({
  src,
  ext = 'jpg',
  deviceSizes = _deviceSizes,
}: {
  src: string
  ext?: 'jpg' | 'avif' | 'webp'
  deviceSizes?: Array<number>
}) => deviceSizes.map((w) => `${loader({ src, w, ext })} ${w}w`).join(', ')

const ProgressiveImage = ({
  src,
  alt,
  title,
  sizes = '100vw',
  ratio = 0,
  deviceSizes = _deviceSizes,
  className,
  placeholder,
  style = {},
  eager,
  children,
}: {
  src: string
  placeholder?: string
  alt: string
  title?: string
  sizes?: string
  ratio?: number
  className?: string
  deviceSizes?: Array<number>
  style?: object
  eager?: boolean
  children?: ReactNode
}) => {
  const [wasInView, setWasInView] = useState<boolean>(Boolean(eager))
  const [isHiResLoaded, setIsHiResLoaded] = useState<boolean>(false)
  const hiResRef = useRef<HTMLImageElement>(null)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isHiResLoaded && hiResRef?.current?.complete) setIsHiResLoaded(true)
    if (!wasInView && inView) setWasInView(true)
  })

  return (
    <div
      ref={ref}
      className={'progressive-responsive-image ' + className}
      style={{
        ...style,
        paddingBottom: `${ratio * 100}%`,
      }}
    >
      {children}
      {(isHiResLoaded || wasInView) && (
        <picture>
          {_extensions.map((ext) => (
            <source
              key={ext}
              srcSet={getSrcSet({ src, ext, deviceSizes })}
              sizes={sizes}
              type={`image/${ext}`}
            />
          ))}
          <img
            style={{ opacity: isHiResLoaded ? 1 : 0 }}
            ref={hiResRef}
            src={loader({
              w: deviceSizes[0],
              src,
              ext: 'jpg',
            })}
            alt={alt}
            title={title}
            onLoad={() => setIsHiResLoaded(true)}
          />
        </picture>
      )}
      {placeholder && (
        <img
          style={{ opacity: isHiResLoaded ? 0 : 1 }}
          src={placeholder}
          alt={alt}
          title={title}
        />
      )}
    </div>
  )
}

export default ProgressiveImage
