import React from 'react'
import Image from '@cms/image'

const HoverImage = ({
  src,
  placeholder,
  alt,
  sizes,
  ratio,
  title,
  desc,
  bottomText,
}: {
  src: string
  placeholder: string
  alt: string
  title?: string
  desc?: string
  bottomText?: string
  sizes?: string
  ratio?: number
}) => {
  return (
    <Image
      className="hover-image"
      src={src}
      placeholder={placeholder}
      sizes={sizes}
      ratio={ratio}
      alt={alt}
    >
      {bottomText && <div className="bottom-text">{title}</div>}
      <div className={'overlay'}>
        {title && <div className="title">{title}</div>}
        {desc && <div className="desc">{desc}</div>}
      </div>
    </Image>
  )
}

export default HoverImage
