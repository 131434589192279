import React from 'react'

import Link from './link'
import Image from './hover-image'
import { cleanUrl } from '@cms/utils'

const Gallery = ({ items }) => {
  return (
    <div className="gallery">
      {items.map(({ en_name, zh_name, thumbnail, id, link }) => (
        <div key={id}>
          <Link href={link}>
            <Image
              title={zh_name}
              desc={en_name}
              placeholder={thumbnail.responsiveImages.placeholder}
              src={cleanUrl(
                `${process.env.NEXT_STRAPI_PUBLIC_ENDPOINT}/${thumbnail.responsiveImages.urlPrefix}`
              )}
              alt={en_name}
              sizes={
                '(min-width: 580px) calc(50vw - 20px - 15px), (min-width: 940px) 280px, calc(100vw - 40px)'
              }
              ratio={1}
            />
          </Link>
        </div>
      ))}
      <div style={{ margin: '0 !important' }} />
      <div style={{ margin: '0 !important' }} />
    </div>
  )
}

export default Gallery
