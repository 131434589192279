import NextLink from 'next/link'
import React, { HTMLProps, FC } from 'react'

const Link: FC<HTMLProps<HTMLAnchorElement>> = ({
  children,
  href,
  ...aProps
}) => {
  if (!href || href.startsWith('http')) {
    return (
      <a href={href} {...aProps}>
        {children}
      </a>
    )
  }

  return (
    <NextLink href={href} passHref>
      <a {...aProps}>{children}</a>
    </NextLink>
  )
}

export default Link
