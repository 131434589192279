export default {
  initial: '#FFF',
  background: '#2B2B2B',
  // button, code_pre, form, link
  // primary: '#957B5F',
  primary: '#A78F75',
  // font, link, button color
  secondary: '#D5D5D5',
  // code block, hr background
  tertiary: '#808080',
  // block quote, form border
  quaternary: '#909090',
  // Table border
  quinary: '#808080',
  // custom,
  dimmer: '#808080',
  error: '#e21c21',
  placeholder: '#eddabc',
}
